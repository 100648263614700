<header class="topbar">
  <nav class="navbar top-navbar navbar-expand-md navbar-dark">
    <div class="navbar-header">
      <a class="navbar-brand">
        <b>
          <img src="././../assets/images/logo/scheduler-hub-logo.png" alt="homepage" class="light-logo" style="width:100%" />
        </b>
      </a>
    </div>

    <div class="navbar-collapse">
      <ul class="navbar-nav me-auto">
        <li class="nav-item"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark"
            href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
        <li class="nav-item"> <a class="nav-link sidebartoggler d-none waves-effect waves-dark"
            href="javascript:void(0)"><i class="icon-menu"></i></a> </li>
      </ul>

      <ul class="navbar-nav me-auto">
        <li class="nav-item"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark"
            href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
        <li class="nav-item"> <a class="nav-link sidebartoggler d-none waves-effect waves-dark"
            href="javascript:void(0)"><i class="icon-menu"></i></a> </li>
      </ul>

      <ul class="navbar-nav my-lg-0">
        <li class="nav-item dropdown u-pro" [matMenuTriggerFor]="sessionMenu">
          <a class="nav-link dropdown-toggle waves-effect waves-dark profile-pic"><span class="hidden-md-down"><i
                class="fa fa-user"></i> {{userObj}}
              &nbsp;<i class="fa fa-angle-down"></i></span> </a>
          <mat-menu #sessionMenu="matMenu" xPosition="before">
            <button mat-menu-item routerLink="/layout/user/profile" ><a class="topSubMenu mat-body-2">Profile</a></button>
            <button mat-menu-item routerLink="/layout/user/change-password" ><a class="topSubMenu mat-body-2">Change
                Password</a></button>
            <button mat-menu-item (click)="logout()"><a class="topSubMenu mat-body-2">Log Out</a></button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </nav>
</header>


<app-user-menu></app-user-menu>
