import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { User } from 'src/models/users/users';
import { CommonService } from 'src/services/common.service';



@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends OnDestroyMixin implements OnInit {

  userObj: User = {};

  constructor(private router: Router, private common: CommonService) {
    super();
  }

  ngOnInit(): void {
    this.viewProfile();
  }

  viewProfile(): void {
    let getId: any = sessionStorage.getItem('userDetail');
    getId = JSON.parse(getId);
    this.userObj = getId.email;
  }

  logout(): void {
    this.common.logout();

  }

}
