import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/guards/auth-guard.service';
import { NotFoundErrorComponent } from 'src/main/error-page/not-found-error/not-found-error.component';
import { LayoutComponent } from './layout.component';

const routes: Routes = [
  {
    path: 'layout',
    component: LayoutComponent,
    children: [
      {
        path: 'report',
        loadChildren: () => import('src/main/report/report.module').then(m => m.ReportModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'dashboard',
        loadChildren: () => import('src/main/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'user',
        loadChildren: () => import('src/main/user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'machine',
        loadChildren: () => import('src/main/machine/machine.module').then(m => m.MachineModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'shifts',
        loadChildren: () => import('src/main/shifts/shifts.module').then(m => m.ShiftsModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'sales',
        loadChildren: () => import('src/main/sales/sales.module').then(m => m.SalesModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'parts',
        loadChildren: () => import('src/main/parts/parts.module').then(m => m.PartsModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'customer',
        loadChildren: () => import('src/main/customer/customer.module').then(m => m.CustomerModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'employee',
        loadChildren: () => import('src/main/employee/employee.module').then(m => m.EmployeeModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'machine-box',
        loadChildren: () => import('src/components/machine-box/machine-box.module').then(m => m.MachineBoxModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'work-center',
        loadChildren: () => import('src/main/workcenter/workcenter.module').then(m => m.WorkcenterModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'assign',
        loadChildren: () => import('src/main/jobs/assign/assign/assign.module').then(m => m.AssignModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '',
    loadChildren: () => import('src/access/signin/signin.module').then(m => m.SigninModule)
  },
  {
    path: 'modify-password',
    loadChildren: () => import('src/access/modify-password/modify-password.module').then(m => m.ModifyPasswordModule)
  },
  {
    path: '**',
    component: NotFoundErrorComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
