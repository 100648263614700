<aside class="left-sidebar">
  <div class="scroll-sidebar">
    <nav class="sidebar-nav">
      <ul id="sidebarnav">
        <li>
          <a
            class="has-arrow waves-effect waves-dark"
            routerLinkActive="active"
            routerLink="/layout/machine-box/machines"
            aria-expanded="false"
            ><i class="ti-layout-grid2"></i
            ><span class="hide-menu"
              >Machine Schedule
              <span class="badge rounded-pill bg-cyan ms-auto">4</span></span
            ></a
          >
        </li>

        <li>
          <a
            class="has-arrow waves-effect waves-dark"
            routerLinkActive="active"
            routerLink="/layout/sales/machine-schedule/production-log"
            aria-expanded="false"
            ><i class="ti-notepad"></i
            ><span class="hide-menu"
              >Production Log
              <span class="badge rounded-pill bg-cyan ms-auto">4</span></span
            ></a
          >
        </li>

        <li *ngIf="usertype != 'Operator'">
          <a
            class="has-arrow waves-effect waves-dark"
            routerLinkActive="active"
            routerLink="/layout/sales/list-sales"
            aria-expanded="false"
            ><i class="ti-layout-grid2"></i
            ><span class="hide-menu"
              >Sales Order
              <span class="badge rounded-pill bg-cyan ms-auto">4</span></span
            ></a
          >
        </li>

        <li *ngIf="usertype != 'Operator'">
          <a
            class="has-arrow waves-effect waves-dark"
            routerLink="/layout/sales/machine-schedule"
            aria-expanded="false"
            ><i class="ti-briefcase"></i
            ><span class="hide-menu"
              >Jobs
              <span class="badge rounded-pill bg-cyan ms-auto">4</span></span
            ></a
          >
        </li>

        <li *ngIf="usertype != 'Operator'">
          <a
            class="has-arrow waves-effect waves-dark"
            routerLinkActive="active"
            routerLink="/layout/dashboard"
            aria-expanded="false"
            ><i class="fa fa-chart-area"></i
            ><span class="hide-menu">
              Management Dashboard
              <span class="badge rounded-pill bg-cyan ms-auto">4</span></span
            ></a
          >
        </li>

        <li *ngIf="usertype != 'Operator'" [matMenuTriggerFor]="reportsMenu">
          <a
            class="has-arrow waves-effect waves-dark"
            href="javascript:void(0)"
            aria-expanded="false"
            ><i class="ti-files"></i
            ><span class="hide-menu">
              Reports
              <span class="badge rounded-pill bg-primary text-white ms-auto"
                >25</span
              ></span
            ></a
          >
          <mat-menu #reportsMenu="matMenu">
            <button
              mat-menu-item
              routerLinkActive="active"
              routerLink="/layout/report/customer-status"
            >
              <a class="topSubMenu mat-body-2">Customer Status Report</a>
            </button>
            <button
              mat-menu-item
              routerLinkActive="active"
              routerLink="/layout/report/gantt-chart"
            >
              <a class="topSubMenu mat-body-2">Order Gantt Chart</a>
            </button>
            <button
              mat-menu-item
              routerLinkActive="active"
              routerLink="/layout/report/wc-utilization"
            >
              <a class="topSubMenu mat-body-2">Workcenter Utilization Report</a>
            </button>
            <button
              mat-menu-item
              routerLinkActive="active"
              routerLink="/layout/report/production-load"
            >
              <a class="topSubMenu mat-body-2">Production Load Report</a>
            </button>
          </mat-menu>
        </li>

        <li *ngIf="usertype != 'Operator'" [matMenuTriggerFor]="settingsMenu">
          <a
            class="has-arrow waves-effect waves-dark"
            href="javascript:void(0)"
            aria-expanded="false"
            ><i class="ti-settings"></i
            ><span class="hide-menu"
              >Settings
              <span class="badge rounded-pill bg-primary text-white ms-auto"
                >25</span
              ></span
            ></a
          >
          <mat-menu #settingsMenu="matMenu">
            <button
              mat-menu-item
              routerLinkActive="active"
              routerLink="/layout/customer/list-customer"
            >
              <a class="topSubMenu mat-body-2">Customers</a>
            </button>
            <button
              mat-menu-item
              routerLinkActive="active"
              routerLink="/layout/parts/list-parts"
            >
              <a class="topSubMenu mat-body-2">Parts Database</a>
            </button>
            <button
              mat-menu-item
              routerLinkActive="active"
              routerLink="/layout/machine/list-machine"
            >
              <a class="topSubMenu mat-body-2">Machines</a>
            </button>
            <button
              mat-menu-item
              routerLinkActive="active"
              routerLink="/layout/shifts/list-shifts"
            >
              <a class="topSubMenu mat-body-2">Shifts</a>
            </button>
            <button
              mat-menu-item
              routerLinkActive="active"
              routerLink="/layout/work-center/list-workcenter"
            >
              <a class="topSubMenu mat-body-2">Workcenters</a>
            </button>
            <button
              mat-menu-item
              routerLinkActive="active"
              routerLink="/layout/user"
            >
              <a class="topSubMenu mat-body-2">Users</a>
            </button>
          </mat-menu>
        </li>
      </ul>
    </nav>
  </div>
</aside>
