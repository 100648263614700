import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-not-found-error',
  templateUrl: './not-found-error.component.html',
  styleUrls: ['./not-found-error.component.scss']
})
export class NotFoundErrorComponent implements OnInit {

  constructor(private _location: Location, private activatedRoute: ActivatedRoute, private router: Router,) { }

  ngOnInit(): void {
  }

  back(): void {
    this._location.back();

  }

}
