import { Injectable, HostListener } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { CommonService } from 'src/services/common.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private commonService: CommonService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = sessionStorage.getItem('userDetail');
    if (!currentUser) {
      this.router.navigate(['/']);
      return false;
    } else if (currentUser) {
      // localStorage.clear();
      return true;
    }
  }
}
