export interface Message {
  id?: any;
  source?: any;
  machineId?: any;
  accessToken?: any;
  status?: any;
  firstName?: any;
}


export enum Status {
  CONNECT = 'Connect',
  SCHEDULER = 'Scheduler',
  USERPRESENT = 'UserPresent',
  EMPTYUSER = 'EmptyUser',
  CHECK = 'Check',
  CLOSE = 'Close',
}
