import { Component, HostListener, OnInit } from '@angular/core';
import { CommonService } from 'src/services/common.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  host: { 'window:beforeunload': 'doSomething' }

})
export class LayoutComponent implements OnInit {

  constructor(private common: CommonService) {

  }

  ngOnInit(): void {
  }


}
