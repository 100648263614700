import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserType } from 'src/models/users/users';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, AfterViewInit {

  usertype: UserType;
  constructor() { }

  ngOnInit(): void {
    this.checkAdmin()
  }

  ngAfterViewInit(): void {
  }

  checkAdmin(): void {
    let ad = sessionStorage.getItem("userDetail");
    ad = JSON.parse(ad);
    this.usertype = ad['role'];
  }



}
