/*socket.service.ts*/
import { Injectable } from '@angular/core';
import { io } from "socket.io-client";
import { Observable, Subscriber } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SocketService {

    socket :any;

    constructor() {

    }

    listen(Eventname : string){
      this.socket = io('http://localhost:3000');
        return new Observable((subscriber)=>{
            this.socket.on(Eventname,(data)=>{
                subscriber.next(data);
            })
        });
    }

    disconnect(){
      console.log('leaving bye');
      this.socket.disconnect();
  }
}
