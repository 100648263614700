import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Message, Status } from 'src/models/message/message';
import { User } from 'src/models/users/users';
import { environment } from "../environments/environment.prod";
import { CheckUserService } from './util/checkuser.service';
import { JobsService } from './api/jobs.service';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SocketService } from './util/socket.service';
const SERVICE_NAME = environment.SERVICE_NAME;


@Injectable({
  providedIn: 'root'
})
export class CommonService extends OnDestroyMixin{
  user: any;

  loginCred: any;
  human: any;
  sessionValidMinutes = 0;


  userObj: User = {};
  message: Message = {};
  status = Status;


  constructor(
    public router: Router, public iziToast: ToastrService,
    private checkUser: CheckUserService, private jobsService: JobsService,
    private srv: SocketService) {
      super();
    this.user = sessionStorage.user;

  }

  setLogin(loginCred): void  {
    this.loginCred = loginCred;
  }
  getLogin(): void  {
    return this.loginCred;

  }

  setUser(user): void  {
    this.user = user;

  }

  getUser(): void  {
    return this.user;
  }


  showSuccessMessage(data): void {
    this.iziToast.success(data);
  }

  showInfoMessage(data): void  {
    this.iziToast.info(data);
  }

  showErrorMessage(data): void  {
    this.iziToast.error(data);
  }

  logout(): void  {
    this.unlockUser();
  }

  unlockUser(): void {
    let machineCheckUser = sessionStorage.getItem('machineId');
    if (machineCheckUser === null || undefined) {
      this.localDestroy();
      return;
    } else {
      machineCheckUser = JSON.parse(machineCheckUser);
      const outside = { action: 'outside', id: machineCheckUser };
      this.unlockUserStatus(outside);
    }
  }


  unlockUserStatus(status?: any): void {
    this.jobsService.updateUserAvailable(status)
      .pipe(untilComponentDestroyed(this))
      .subscribe(result => {
        if (result.responseStatus === 200) {
          this.localDestroy();
        }
      });
  }

  localDestroy(): void {
    // this.srv.disconnect();
    sessionStorage.clear();
    this.setUser({});
    this.router.navigate(['/']);
    this.checkUser.deactivateUser();
  }



  downloadFile(action, filename): void {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = SERVICE_NAME + 'downloadReports?action=' + action;
    a.download = filename;
    a.click();
    a.remove();
  }




}
