import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from 'src/layout/layout.module';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
// import { RxStompService } from '@stomp/ng2-stompjs';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatePipe } from '@angular/common';
import { BnNgIdleService } from 'bn-ng-idle';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { ToastrModule } from 'ngx-toastr';
import { NgOptimizedImage } from '@angular/common';
import { AppInitService } from 'src/services/util/app-init.service';
import { RxStompService } from 'src/services/util/rx-stomp.service';
import { AuthorizationHeaderInterceptor } from 'src/guards/AuthorizationAuthInterceptor';

export function appInit(appInitService: AppInitService) {
  return () => appInitService.init();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NgOptimizedImage,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    MatMomentDateModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    provideClientHydration(),
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        strict: true,
      },
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA],
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHeaderInterceptor,
      multi: true,
    },
    BnNgIdleService,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: RxStompService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
