import { RxStompConfig } from '@stomp/rx-stomp';
import { CheckUserService } from './checkuser.service';
import { HttpXsrfTokenExtractor } from '@angular/common/http';
import { WorkcenterService } from '../api/workcenter.service';


export class myRxStompConfig extends RxStompConfig {
  // Which server?
  // brokerURL: 'ws://127.0.0.1:15674/ws',
  constructor(private httpXsrfTokenExtractor: HttpXsrfTokenExtractor,
    private checkUser: CheckUserService,
    // private configurationService: ConfigurationService,
    private workService: WorkcenterService
    ) {
    super();

    this.connectHeaders = {
      'Client-Id': 'Ghub',
      'X-XSRF-TOKEN': this.httpXsrfTokenExtractor.getToken()
    };
    this.heartbeatIncoming = 0;
    this.heartbeatOutgoing = 2000;

    this.reconnectDelay = 5000;

    this.beforeConnect = (stompClient: any): Promise<void> =>
      new Promise<void>((resolve, reject) => {
        this.workService.getWorkcenterMaster()
          .subscribe({
            next: (configuration) => {
              stompClient.stompClient.brokerURL = `ws://localhost:4343/messages?jwt=${new Date().getTime()}`;
              resolve();
            }, error: (err) => {
              resolve();
            }
          });
      });
  }


}
