import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Sales } from 'src/models/sales/sales';
import { Pageable } from 'src/models/page/pageable';
import { environment } from 'src/environments/environment.prod';


const SERVICE_NAME = environment.SERVICE_NAME;

@Injectable({
  providedIn: 'root'
})

export class JobsService {

  constructor(private http: HttpClient) {
  }


  getMachineSchedule(pageable: Pageable): Observable<any> {
    return this.http.post<any>(SERVICE_NAME + 'getmachineSchedule', pageable);
  }

  getJobIDList(pagination?: number): Observable<any> {
    return this.http.get<any>(SERVICE_NAME + 'listjobids');
  }

  getJobsList(pagination?: number): Observable<any> {
    return this.http.post<any>(SERVICE_NAME + 'listjobs', pagination);
  }

  viewJobsList(partId?: string): Observable<Sales> {
    return this.http.post<any>(SERVICE_NAME + 'getJobById', partId);
  }

  updateJobsList(partId?: any): Observable<Sales[]> {
    return this.http.put<any>(SERVICE_NAME + 'updateJob', partId);
  }

  getJobEndDate(salesObj?: any): Observable<Sales> {
    return this.http.put<any>(SERVICE_NAME + 'getJobEndDate', salesObj);
  }

  transferJob(partId?: any): Observable<Sales> {
    return this.http.put<any>(SERVICE_NAME + 'transferJob', partId);
  }
  getJobsByMachineId(macineName?: any): Observable<Sales> {
    return this.http.post<any>(SERVICE_NAME + 'getJobsByMachineId', {machineId: macineName});
  }

  getJobEndDateForMachineIdAndJobOrder(salesObj?: any): Observable<Sales> {
    return this.http.post<any>(SERVICE_NAME + 'getJobEndDateForMachineIdAndJobOrder', salesObj);
  }

  unscheduleJobById(salesObj?: any): Observable<Sales> {
    return this.http.post<any>(SERVICE_NAME + 'unscheduleJobById', salesObj);
  }

  checkUserAvailable(): Observable<any> {
    return this.http.get<any>(SERVICE_NAME + 'checkUserAvailable');
  }

  updateUserAvailable(status?: {}): Observable<any> {
    return this.http.put<any>(SERVICE_NAME + 'updateUserAvailable', status);
  }

  replaceAllUsersInactive(status?: {}): Observable<Sales> {
    return this.http.put<any>(SERVICE_NAME + 'replaceAllUsersInactive', status);
  }
}
