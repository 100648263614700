import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Workcenter } from 'src/models/workcenter/workcenter';
import { environment } from 'src/environments/environment.prod';

const SERVICE_NAME =  environment.SERVICE_NAME;

@Injectable({
  providedIn: 'root'
})

export class WorkcenterService {

  constructor(private http: HttpClient) {
  }


  getWorkcenter(pagination?: any): Observable<Workcenter> {
    return this.http.post<any>(SERVICE_NAME + 'listWorkCenters', pagination);
  }

  getWorkcenterMaster(): Observable<Workcenter[]> {
    return this.http.get<any>(SERVICE_NAME + 'listWorkCenterMaster');
  }

  createWorkcenter(workcenter?: any): Observable<Workcenter[]> {
    return this.http.post<any>(SERVICE_NAME + 'createWorkCenter', workcenter);
  }

  viewWorkcenter(workcenterId?: string): Observable<Workcenter> {
    return this.http.post<any>(SERVICE_NAME + 'getWorkCenterById', workcenterId);
  }

  updateWorkcenter(workcenter?: any): Observable<Workcenter[]> {
    return this.http.put<any>(SERVICE_NAME + 'updateWorkCenter', workcenter);
  }

  deleteWorkcenter(workcenter?: any): Observable<Workcenter[]> {
    return this.http.post<any>(SERVICE_NAME + 'deleteWorkCenter', {id: workcenter});
  }


}
