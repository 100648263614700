import { Injectable } from '@angular/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { RxStompService } from './rx-stomp.service';
import { HttpXsrfTokenExtractor } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { myRxStompConfig } from './rx-stomp.config';
import { WorkcenterService } from '../api/workcenter.service';
import { Subscription } from 'rxjs';
import { IMessage } from '@stomp/rx-stomp';

@Injectable({
  providedIn: 'root'
})

export class CheckUserService extends OnDestroyMixin {

  filterSubscription: Subscription;

  constructor(private rxStompService: RxStompService,
    private configurationService: ConfigurationService,
    private httpXsrfTokenExtractor: HttpXsrfTokenExtractor,
    private workService: WorkcenterService
    ) {
    super();
    this.subscribeForServer();
  }
  initialize(): void {
    this.rxStompService.configure(new myRxStompConfig(this.httpXsrfTokenExtractor, this, this.workService));
    this.rxStompService.activate();
    this.loadUser();
  }

   loadUser(): void {
    // setTimeout(() =>{this.deactivateUser();}, 9000)

    // this.filterSubscription = this.jobsService.getJobsByMachineId()
    // .pipe().
    //   untilComponentDestroyed(this)
    // .subscribe(result => {
    //   if (result) {

    //   }
    // });


  }
  subscribeForServer(): void {
    const topicSubscription = this.rxStompService.watch('/user/topic/messages', {ack: 'client-individual'});
    topicSubscription.subscribe((body: IMessage) => {
      const user = JSON.parse(body.body);
      console.log(user, 'user');
    })
  }

   deactivateUser(): void {
    this.rxStompService.deactivate();
    console.log('Leaving bye');
  }

}
