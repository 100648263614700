import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CheckUserService } from './checkuser.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AppInitService {

  constructor(private checkUserService: CheckUserService) {

  }

  init(): any {
    // return this.checkUserService.initialize();
    // let initializer;
    // return initializer
    //   .pipe(
    //     tap(() => {
    //       this.checkUserService.initialize();
    //     })
    //   )
  }
}
