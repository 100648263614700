import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateFilterPipe } from 'src/pipes/template-filter.pipe';
import { CustomeTableFilterPipe } from 'src/pipes/custome-table-filter.pipe';

const PIPES = [
  TemplateFilterPipe,
  CustomeTableFilterPipe,
];


@NgModule({
  declarations: [PIPES],
  providers: [],
  exports: [
  ],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
